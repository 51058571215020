<template>
  <div class="facture-compta-rh">
    <b-button
      variant="success"
      class="config-btn ml-2"
      @click="fixDate"
      v-b-modal.addFactureComptaRh
      :title="'Créer des factures pour ' + type"
    >
      <font-awesome-icon icon="file-medical" class="file-medical"
    /></b-button>
    <b-modal
      id="addFactureComptaRh"
      ref="addFactureComptaRh"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap cutsom-modal-bootstrap-compta-rh"
    >
      <div class="hader mb-2">
        <div class="titleSetting">Nouvelle facture</div>
        <div class="iconClose" @click.prevent="hideModal('addFactureComptaRh')">
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleSubmitNewFacture"
            class="form-modal-custom-style"
          >
            <div class="ModelCol ">
              <b-form-group label="Année" label-for="year" class="mr-2">
                <b-form-select
                  id="year"
                  v-model="filterYear"
                  :options="ListYear"
                  class="b-form-select-raduis "
                  @change="handleChangeYearModal"
                ></b-form-select>
              </b-form-group>
              <b-form-group
                label="Mois"
                label-for="month"
                class="input-modal-champ"
              >
                <b-form-select
                  id="month"
                  v-model="filterMonth"
                  :options="computedGetMonthForYearTh"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis"
                  required
                  @change="handleChangeMonthModal"
                ></b-form-select>
              </b-form-group>
            </div>
            <b-form-group
              label="Filiale Installateur"
              label-for="page"
              class="input-modal-champ mt-1"
            ></b-form-group>

            <multiselect
              v-model="factureToAdd"
              :options="ComputedListFiliale"
              placeholder="Rechercher une filiale"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              label="name"
              track-by="name"
              :preselect-first="false"
              class="mb-2"
              :loading="loadingSelect"
            >
            </multiselect>
            <div class="box-items-facture" v-if="factureToAdd">
              <div
                class="box-item-facture"
                v-for="facture in factureToAdd"
                :key="facture.name"
              >
                <div class="item-facture-title">{{ facture.name }}</div>
                <b-form-group
                  label="Montant HT"
                  label-for="nom_filiale"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="nom_filiale"
                    v-model="facture.montant"
                    required
                    pattern="[0-9-,-.]+"
                    title="This input should be only numbers"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)" class="mb-0">
                    <li v-for="(e, index) in error" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style ">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  props: {
    type: { required: true },
    month: { required: true },
    year: { required: true }
  },
  data() {
    return {
      factureToAdd: [],
      error: null,
      loading: false,
      filterMonth: this.month,
      filterYear: this.year,
      ListYear: [],
      loadingSelect: false
    };
  },
  computed: {
    ...mapGetters(['getSettingFilialesTh', 'getMonthForYearTh']),
    ComputedListFiliale() {
      return this.getSettingFilialesTh.map(item => {
        return {
          name: item.name,
          id: item.id,
          montant: 0
        };
      });
    },
    computedGetMonthForYearTh() {
      const tab = this.getMonthForYearTh.map(item => ({
        value: item.month_name,
        text: item.month_name
      }));
      return tab;
    }
  },
  methods: {
    ...mapActions([
      'getSettingFilialeTh',
      'addNewFactureComptaRhActions',
      'fetchMonthForYearTH',
      'generateFactureComptaRh'
    ]),
    resetModal() {
      this.factureToAdd = [];
      this.error = null;
      this.loading = false;
      this.ListYear = [];
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    handleChangeYearModal() {
      this.factureToAdd = [];
      this.filterMonth = null;
      this.fetchMonthForYearTH(this.filterYear);
    },
    async handleChangeMonthModal() {
      this.loadingSelect = true;
      const response = await this.generateFactureComptaRh({
        type: this.type,
        year: this.filterYear,
        month: this.filterMonth
      });
      if (response.succes) {
        this.factureToAdd = [];
        for (let i = 0; i < response.data.length; i++) {
          this.factureToAdd.push({
            name: response.data[i].filiale.name,
            id: response.data[i].filiale.id,
            montant: response.data[i].montant
          });
        }
      }
      this.loadingSelect = false;
    },
    fixDate() {
      var courantYear = moment().year();
      this.filterMonth = this.month;
      this.filterYear = this.year;
      for (var i = 2019; i <= courantYear; i++) {
        this.ListYear.push({ value: i, text: i });
      }
      if (this.filterMonth != null) {
        this.handleChangeMonthModal();
      }

      this.getSettingFilialeTh();
    },
    async handleSubmitNewFacture() {
      if (this.factureToAdd.length) {
        this.loading = true;
        var params = new FormData();
        params.append('year', this.filterYear);
        params.append('month', this.filterMonth);
        params.append('type', this.type);

        for (let i = 0; i < this.factureToAdd.length; i++) {
          params.append('filiales[' + i + '][id]', this.factureToAdd[i].id);
          params.append(
            'filiales[' + i + '][montant]',
            this.factureToAdd[i].montant
          );
        }
        const response = await this.addNewFactureComptaRhActions(params);
        if (response.succes) {
          this.hideModal('addFactureComptaRh');
          let errorMessage =
            response.message.reworked && response.message.reworked > 0
              ? '\n' +
                '<div>Factures modifer : ' +
                response.message.reworked +
                ' <span style="font-size: 18px;border-radius: 50%;background-color: #ff0000d9;padding: 1px 7px;color: #fff;" title="Total zéro">!</span></div>'
              : '';
          let text =
            '<div style="dispaly:flex"><div>Factures ajouté avec succès  : ' +
            response.message.success +
            '\n' +
            '\n' +
            '</div><div>Factures déja payé : ' +
            response.message.already_paied +
            '</div>' +
            errorMessage;

          this.$alert('', text, 'success').then(() => {
            this.$emit('reloadData', {
              year: this.filterYear,
              month: this.filterMonth
            });
          });
        } else {
          this.error = response.error;
          this.loading = false;
        }
      } else {
        this.error = 'Filiale required';
      }
    }
  },
  components: {
    Card: () => import('@/views/component/card.vue')
  }
};
</script>

<style lang="scss" scoped>
.facture-compta-rh {
  .file-medical {
    font-size: 13px;
  }
}
.box-items-facture {
  .box-item-facture {
    padding: 5px;
    border: 1px solid #e8e6f2;
    border-radius: 10px;
    margin-bottom: 5px;
    margin-top: 5px;
    display: flex;
    color: black;
    justify-content: space-between;
    .item-facture-title {
      align-self: center;
      text-align: start;
      font-size: 14px;
      margin-bottom: 7px;
      font-weight: 600;
      font-family: 'Montserrat', sans-serif;
    }
    .icon {
      align-self: center;
    }
  }
}
</style>
<style lang="scss">
.modal-facture {
  .modal-dialog {
    max-width: 550px !important;
    margin: auto !important;
  }
}
.multiselect__tag {
  background: #4d4bac;
}
.multiselect__tag-icon:after {
  color: #ffffff;
}
.box-items-facture {
  .box-item-facture {
    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #4d4bac !important;
      background-color: #4d4bac !important;
    }
  }
}
.cutsom-modal-bootstrap-compta-rh .modal-dialog {
  top: 2%;
  max-width: 45%;
  margin: auto;
}
@media screen and (max-width: 925px) {
  .cutsom-modal-bootstrap-compta-rh .modal-dialog {
    top: 2%;
    max-width: 80%;
    margin: auto;
  }
}
</style>
